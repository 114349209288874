<template>
  <div class="business-oportunity">
    <div class="wrap-content hide-scrollbar" @scroll="onWrapScroll">
      <div class="wrap-content__header">
        <mini-program-navbar
          :style="{
            backgroundColor: `rgb(255 255 255 / ${headerNavbarStyle.opacity})`,
          }"
          >创胜邦</mini-program-navbar
        >

        <!-- 搜索 -->
        <div
          class="wrap-search"
          :style="{
            top: headerNavbarStyle.top + 'px',
            transform: `scale(${headerNavbarStyle.scale})`,
          }"
        >
          <div class="view-left">
            <div class="image">
              <img :src="searchIcon" alt="" />
            </div>
            <div class="tips">想找点啥？</div>
          </div>
          <div class="view-right">
            <span>搜索</span>
          </div>
        </div>
      </div>

      <div class="wrap-content__body hide-scrollbar">
        <!-- 导航盒子 -->
        <div class="navigation-box">
          <div class="wrap-navigation">
            <div
              class="item"
              v-for="(item, index) in navbarData"
              :key="index + 'navbar'"
              :class="`after${index}`"
            >
              <div class="bar" v-if="index"></div>

              <div class="navbar-item">
                <img :src="item.icon" alt="" />
                <div class="item-label">
                  {{ item.label }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 筛选项 -->
        <div class="tab-box">
          <div class="sticky-box">
            <div class="tab-top hide-scrollbar">
              <div
                class="tab-item"
                v-for="(item, index) in tabTopList"
                :key="'tab' + index"
                :class="{ active: !item.value }"
                :style="{ minWidth: item.minWidth + 'px' }"
              >
                <span>{{ item.lable }}</span>
              </div>
              <div class="line"></div>
            </div>
            <div class="tab-select">
              <div class="tab-select_item">
                <span>综合排序</span>
                <img :src="arrowDown" alt="" />
              </div>
              <div class="line"></div>
              <div class="tab-select_item">
                <span>不限身份</span>
                <img :src="arrowDown" alt="" />
              </div>
            </div>
            <div class="tab-tag hide-scrollbar">
              <div
                class="tag-item"
                v-for="(item, index) in hotWordTagsList"
                :key="'tag' + index"
                :style="{ minWidth: item.minWidth + 'px' }"
              >
                {{ item.lable }}
              </div>
            </div>
          </div>
          <div class="container ml-scrollbar">
            <div
              v-for="(item, index) in contentDataList"
              :key="'content' + index"
            >
              <userCard
                v-for="(value, childIndex) in item.userInfoList"
                :key="'child' + childIndex"
                :userInfoList="value"
              ></userCard>
              <consultBox :contentData="item.content"></consultBox>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <tabbarBox :tabbarData="tabbarData"></tabbarBox>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import miniProgramNavbar from "../mini-program-navbar/index";
import tabbarBox from "../tabbar/index.vue";
import userCard from "./components/user-card.vue";
import consultBox from "./components/consult-box.vue";

/* 图片 */
// 箭头
const arrowDown = require("@/assets/images/imitatepage/chuang-sheng-bang/arrow-down.png");
const searchIcon = require("@/assets/images/imitatepage/chuang-sheng-bang/sreach.png");

// 导航数据
const navbarData = [
  {
    label: "成为专家",
    icon: require("@/assets/images/imitatepage/chuang-sheng-bang/navigation-0.png"),
    boxShadow: " 0px 10px 20px 1px rgba(255,210,146,0.55)",
  },
  {
    label: "官方招募",
    icon: require("@/assets/images/imitatepage/chuang-sheng-bang/navigation-1.png"),
    boxShadow: "0px 10px 20px 1px rgba(220,198,255,0.6)",
  },
  {
    label: "人才短租",
    icon: require("@/assets/images/imitatepage/chuang-sheng-bang/navigation-2.png"),
    boxShadow: "0px 10px 20px 1px rgba(162,245,255,0.5)",
  },
  {
    label: "产品代理",
    icon: require("@/assets/images/imitatepage/chuang-sheng-bang/navigation-3.png"),
    boxShadow: "0px 10px 20px 1px rgba(145,174,255,0.45)",
  },
];

// 顶部筛选数据
const tabTopList = [
  { lable: "全部", value: 0, minWidth: 55 },
  { lable: "设计", value: 1, minWidth: 55 },
  { lable: "技术", value: 2, minWidth: 55 },
  { lable: "咨询", value: 3, minWidth: 55 },
  { lable: "运营", value: 4, minWidth: 55 },
  { lable: "小程序", value: 5, minWidth: 70 },
  { lable: "软件开发", value: 6, minWidth: 80 },
];

// 热词标签
const hotWordTagsList = [
  { lable: "世界杯", minWidth: 65 },
  { lable: "疫情", minWidth: 55 },
  { lable: "wpa3", minWidth: 55 },
  { lable: "食品", minWidth: 55 },
  { lable: "韩国防疫", minWidth: 80 },
  { lable: "这里是后台设置的标签", minWidth: 160 },
];

// tabbar
const tabbarData = [
  {
    icon: require("@/assets/images/imitatepage/chuang-sheng-bang/tabbar/home-select.png"),
    text: "首页",
    color: "#102f4b",
    isConvention: true,
    bold: 600,
  },
  {
    icon: require("@/assets/images/imitatepage/chuang-sheng-bang/tabbar/square.png"),
    text: "广场",
    color: "#999999",
    isConvention: true,
  },
  {
    icon: require("@/assets/images/imitatepage/chuang-sheng-bang/tabbar/people.png"),
    text: "人脉",
    color: "#999999",
    isConvention: true,
  },
  {
    icon: require("@/assets/images/imitatepage/chuang-sheng-bang/tabbar/my.png"),
    text: "我的",
    color: "#999999",
    isConvention: true,
  },
];

// 内容数据
const contentDataList = [
  {
    userInfoList: [
      {
        headSculpture: require("@/assets/images/imitatepage/chuang-sheng-bang/user-avatar.png"),
        isShowOnlineIcon: true,
        isShowAttestationBar: true,
        name: "王美丽",
        rate: "9.8",
        consoult: "2000",
        title: "上海人大教授，曾参加过XX国家级项目。",
        serveTitle: "这里是服务产品的标题",
        servePrice: 199.9,
      },
      {
        headSculpture: require("@/assets/images/imitatepage/chuang-sheng-bang/default-avatar.png"),
        isShowOnlineIcon: true,
        isShowAttestationBar: false,
        name: "深圳市印心之力...",
        rate: "8.0",
        consoult: "1.5W",
        title: "我司从事XX行业，产品多样化，欢迎来电咨询...",
        serveTitle: "这里是一条标题",
        servePrice: 299.9,
      },
    ],
    content: {
      userAvatar: require("@/assets/images/imitatepage/chuang-sheng-bang/default-avatar.png"),
      authAvatar: require("@/assets/images/imitatepage/chuang-sheng-bang/auth-avatar.png"),
      name: "深圳市印心之力投资咨询有限...",
      title: "为什么使用wpa3的SAE连接会出问题",
      time: "5分钟前",
      text: "mlme.c文件是Linux内核中用于管理Wi-Fi网络连接的模块。在STA模式下，如果使用了WPA3的SAE（Simultaneous）连接...",
      video: require("@/assets/images/imitatepage/chuang-sheng-bang/video-cover.png"),
      goodUp: true,
      share: 86,
      leave: 500,
      like: 1981,
    },
  },
  {
    userInfoList: [
      {
        headSculpture: require("@/assets/images/imitatepage/chuang-sheng-bang/user-avatar.png"),
        isShowOnlineIcon: true,
        isShowAttestationBar: true,
        name: "王美丽",
        rate: "9.8",
        consoult: "2000",
        title: "上海人大教授，曾参加过XX国家级项目。",
        serveTitle: "这里是服务产品的标题",
        servePrice: 158.8,
      },
      {
        headSculpture: require("@/assets/images/imitatepage/chuang-sheng-bang/default-avatar.png"),
        isShowOnlineIcon: false,
        isShowAttestationBar: false,
        name: "深圳市印心之力投资咨询有限",
        rate: "8.0",
        consoult: "1.5W",
        title: "我司从事XX行业，产品多样化，欢迎来电咨询...",
        serveTitle: "这里是一条标题",
        servePrice: 356.6,
      },
    ],
    content: {
      userAvatar: require("@/assets/images/imitatepage/chuang-sheng-bang/user-avatar.png"),
      authAvatar: require("@/assets/images/imitatepage/chuang-sheng-bang/auth-avatar.png"),
      name: "王美丽",
      title: "为什么使用wpa3的SAE连接会出问题",
      time: "半个小时前",
      text: "mlme.c文件是Linux内核中用于管理Wi-Fi网络连接的模块。在STA模式下，如果使用了WPA3的SAE（Simultaneous）连接...",
      video: "",
      goodUp: false,
      share: 86,
      leave: 500,
      like: 1981,
    },
  },
];

// *************************************************************************************************
// 数据操作 START
// *************************************************************************************************
/* 监听内容滚动 */
const wrapScrollTop = ref(0);
const onWrapScroll = (e) => {
  const scrollTop = e.target.scrollTop;
  wrapScrollTop.value = scrollTop;
};
// 顶部搜索导航的样式变动
const headerNavbarStyle = computed(() => {
  const scrollTop = wrapScrollTop.value;
  // 目标高度
  const targetHeight = 44;

  // 百分比
  const scale = scrollTop / targetHeight;
  const useScale = scale < 1 ? scale : 1;

  // 指定截止数值
  // 缩放比例
  const searchMaxScale = 0.25;
  // 上top
  const searchMaxTop = 38;

  // 实际渲染样式
  const realScale = 1 - searchMaxScale * useScale;
  const realTop = searchMaxTop * useScale;

  return {
    scale: realScale,
    top: -realTop,
    opacity: useScale,
  };
});

// *************************************************************************************************
// 数据操作 END
// *************************************************************************************************
</script>

<style lang="scss" scoped>
.business-oportunity {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .wrap-content {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    padding: 0 2px;
    box-sizing: border-box;
    overflow-y: auto;
    background-image: url("../../../../assets/images/imitatepage/chuang-sheng-bang/top-bg-1.png");
    background-repeat: no-repeat;
    background-size: calc(100% - 4px) auto;
    background-position: top;
    // background-size: 100% auto;

    &__header {
      position: sticky;
      top: 0;
      z-index: 3;
      overflow: hidden;

      ::v-deep(.mini-program-navbar) {
        padding: 20px;
        box-sizing: border-box;
        height: auto;
        padding-top: 45px;
        padding-bottom: 10px;

        .capsule-fixed {
          top: 35px;
        }
      }

      // 搜索
      .wrap-search {
        border-radius: 50px;
        box-shadow: 0px 0px 12px rgba(167, 167, 167, 0.16);
        display: grid;
        grid-template-columns: 1fr 25%;
        background-color: #fff;
        position: relative;
        z-index: 0;
        width: 100%;
        height: 40px;
        box-sizing: border-box;
        width: calc(100% - 40px);
        margin: auto;

        // transform: scale(0.72);
        transform-origin: left top;
        // top: -35px;

        .view-left {
          padding: 0 15px;
          color: #999;
          height: 100%;
          display: flex;
          align-items: center;

          .image {
            width: 18px;
            height: 18px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .tips {
            margin-left: 10px;
            font-size: 14px;
          }
        }
        .view-right {
          background-color: #708aff;
          color: #fff;
          border-radius: 50px;
          display: flex;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          text-align: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;

          span {
            font-size: 16px;
          }
        }
      }
    }

    &__body {
      // height: calc(100% - 5px);
      margin-top: 40px;
      height: fit-content;
      // height: 100vh;
      // background-color: white;

      // 导航盒子
      .navigation-box {
        padding-bottom: 10px;
        background-color: #eceff6;

        .wrap-navigation {
          display: flex;
          justify-items: center;
          align-items: center;
          flex-direction: row;
          justify-content: center;
          background-color: white;
          padding-bottom: 5px;
          padding-top: 10px;
          .item {
            display: flex;
            flex-direction: row;
            justify-content: center;
            position: relative;
            &::after {
              content: " ";
              position: absolute;
              width: 56px;
              height: 56px;
              border-radius: 60px;
              top: 0;
              right: 0;
              opacity: 0.5;
              border-top-left-radius: 30px;
              border-top-right-radius: 30px;
              border-bottom-right-radius: 30px;
              border-bottom-left-radius: 30px;
            }

            $shadowColors: (
              0: #ffd2928c,
              1: #dcc6ff99,
              2: #a2f5ff80,
              3: #91aeff73,
            );

            @each $index, $item in $shadowColors {
              &.after#{$index} {
                &::after {
                  box-shadow: 0px 20px 40px $item, 0 50px 40px $item inset;
                }
              }
            }

            .bar {
              width: 1px;
              height: 40px;
              background: linear-gradient(
                180deg,
                #ececec 0%,
                #cecece 52%,
                #ececec 100%
              );
              margin: 10px 16px;
              opacity: 0.5;
            }
            .navbar-item {
              color: rgba(51, 51, 51, 1);
              img {
                width: 56px;
                height: 56px;
              }
              .item-label {
                font-size: 14px;
                font-weight: bold;
                line-height: 25px;
              }
            }
          }
        }
      }

      // 筛选
      .tab-box {
        background-color: #fff;

        .sticky-box {
          background-color: white;
          z-index: 9;
          position: sticky;
          top: 77px;
          padding-bottom: 10px;
        }
        .tab-top {
          display: flex;
          overflow-x: auto;
          position: relative;
          color: rgba(51, 51, 51, 1);
          font-weight: 700;
          padding: 0 10px;
          box-sizing: border-box;
          width: 100%;
          .tab-item {
            padding: 10px;
            box-sizing: border-box;
            font-size: 14px;
            // min-width: 80px;

            &.active {
              font-size: 16px;
            }
          }

          .line {
            position: absolute;
            left: 27px;
            bottom: 6px;
            width: 16px;
            height: 2px;
            background-color: rgba(55, 91, 255, 1);
          }
        }
        .tab-select {
          font-size: 14px;
          display: flex;
          align-items: center;
          padding: 5px 20px 13px;
          font-weight: 700;
          color: rgba(51, 51, 51, 1);
          &_item {
            img {
              width: 12px;
              height: 6px;
              margin-left: 2px;
            }
          }

          .line {
            width: 1px;
            height: 20px;
            background: linear-gradient(
              180deg,
              #ececec 0%,
              #cecece 52%,
              #ececec 100%
            );
            margin: 0 15px;
          }
        }
        .tab-tag {
          display: flex;
          align-items: center;
          color: rgba(112, 126, 137, 1);
          overflow-x: auto;
          width: 100%;
          padding: 0 20px;
          box-sizing: border-box;
          .tag-item {
            height: 24px;
            font-size: 14px;
            background: #f2f4f9;
            border-radius: 4px;
            text-align: center;
            line-height: 24px;
            padding: 0 10px;
            box-sizing: border-box;
            margin-right: 10px;

            &:last-child {
              margin-right: 0px;
            }
          }
        }

        // 内容
        .container {
          height: 100%;
          padding: 0 20px;
          box-sizing: border-box;
          overflow-y: auto;
        }
      }
    }
  }

  // tabbar
  .footer {
    position: sticky;
    bottom: 0;
    width: 100%;
    box-shadow: 2px 0px 8px 1px #e4e3e3;
    box-sizing: border-box;
    z-index: 2;
  }
}
</style>

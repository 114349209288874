<template>
  <div class="user-card">
    <div class="user-card_left">
      <img :src="userInfoList.headSculpture" alt="" />
      <img
        class="attestation"
        :src="attestationBar"
        alt=""
        v-if="userInfoList.isShowAttestationBar"
      />
      <img
        class="online"
        :src="onlineIcon"
        alt=""
        v-if="userInfoList.isShowOnlineIcon"
        :class="{ 'only-online': !userInfoList.isShowAttestationBar }"
      />
    </div>
    <div class="user-card_right">
      <div class="top">
        <div class="name">
          <span class="oneLineOver">{{ userInfoList.name }}</span>
          <div class="rate-box">
            <img :src="rateIcon" alt="" />
            <span>{{ userInfoList.rate }}</span>
          </div>
        </div>

        <span class="consult">
          <span class="highlight">{{ userInfoList.consoult }}</span>
          人咨询过
        </span>
      </div>
      <div class="title oneLineOver">
        <span>{{ userInfoList.title }}</span>
      </div>
      <div class="user-tag">
        <div
          class="tag-item"
          v-for="(item, index) in hotWordTagsList"
          :key="'tag' + index"
          :style="{ minWidth: item.minWidth + 'px' }"
        >
          <span>{{ item.lable }}</span>
        </div>
      </div>
      <div class="service-box">
        <div class="title oneLineOver">{{ userInfoList.serveTitle }}</div>
        <div class="price">
          <span class="number">{{ userInfoList.servePrice }}</span>
          <span>元起/天</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  userInfoList: {
    type: Object,
    default: () => ({}),
  },
});

const onlineIcon = require("@/assets/images/imitatepage/chuang-sheng-bang/is-online.png");
const attestationBar = require("@/assets/images/imitatepage/chuang-sheng-bang/attestation-bar.png");
// 评分
const rateIcon = require("@/assets/images/imitatepage/chuang-sheng-bang/start.png");

// 标签
const hotWordTagsList = [
  { lable: "UI设计师", minWidth: 50 },
  { lable: "产品经理", minWidth: 50 },
  { lable: "西厨", minWidth: 30 },
];
</script>

<style lang="scss" scoped>
.user-card {
  padding: 15px 0;
  border-top: 1px solid #f2f4f9;
  display: grid;
  grid-template-columns: auto 1fr;

  //   左侧盒子
  &_left {
    width: 90px;
    height: 90px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }

    .attestation {
      position: absolute;
      width: 50px;
      height: 16px;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .online {
      position: absolute;
      width: 42px;
      height: 16px;
      top: 0;
      left: 42px;

      &.only-online {
        left: 0px;
      }
    }
  }

  //   右侧盒子
  &_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      img {
        width: 14px;
        height: 14px;
      }

      .name {
        display: flex;
        align-items: center;

        span {
          font-weight: 700;
          max-width: 110px;
          color: rgba(51, 51, 51, 1);
        }
        .rate-box {
          display: flex;
          align-items: center;
          margin-left: 2px;
          img {
            margin-right: 2px;
          }

          span {
            font-size: 11px;
            color: rgba(183, 113, 25, 1);
          }
        }
      }

      .consult {
        font-size: 11px;
        color: rgba(102, 102, 102, 1);
        .highlight {
          color: rgba(55, 91, 255, 1);
          font-weight: 700;
        }
      }
    }

    .title {
      span {
        font-size: 11px;
        color: rgba(153, 153, 153, 1);
        overflow: hidden;
      }
    }

    .user-tag {
      display: flex;
      align-items: center;
      color: rgba(112, 126, 137, 1);
      width: 100%;
      margin: 2px 0;
      box-sizing: border-box;
      .tag-item {
        margin-right: 10px;

        &:last-child {
          margin-right: 0px;
        }

        span {
          display: inline-block;
          height: 18px;
          background: #f2f4f9;
          color: rgba(112, 126, 137, 1);
          border-radius: 4px;
          text-align: center;
          line-height: 18px;
          padding: 0px 5px;
          box-sizing: border-box;
          font-size: 10px;
        }
      }
    }

    .service-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: rgba(51, 51, 51, 1);
      .title {
        font-size: 14px;
        font-weight: 700;
        max-width: 140px;
      }

      .price {
        font-size: 18px;
        color: rgba(255, 100, 100, 1);

        .number {
          font-weight: 700;
        }
        span:last-child {
          font-size: 11px;
        }
      }
    }
  }
}
</style>

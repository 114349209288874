<template>
  <div class="consult-box">
    <div class="user-info">
      <div class="user-info_left">
        <img :src="contentData.userAvatar" alt="" />
        <img class="auth-avatar" :src="contentData.authAvatar" alt="" />
      </div>
      <div class="user-info_right">
        <div class="info">
          <div class="name">{{ contentData.name }}</div>
          <div class="user-tag">
            <div
              class="tag-item"
              v-for="(item, index) in hotWordTagsList"
              :key="'tag' + index"
              :style="{ minWidth: item.minWidth + 'px' }"
            >
              <span>{{ item.lable }}</span>
            </div>
          </div>
        </div>
        <div class="btn">
          <el-icon color="rgba(55, 91, 255, 1)"><Plus /></el-icon>
          <span>关注</span>
        </div>
      </div>
    </div>
    <div class="content-box">
      <div class="title-box">
        <div class="title oneLineOver">{{ contentData.title }}</div>
        <span>{{ contentData.time }}</span>
      </div>
      <div class="content ml-line-2">
        {{ contentData.text }}
      </div>
      <div class="video-box" v-if="contentData.video">
        <img :src="contentData.video" alt="" />
        <div class="video-action-icon">
          <img :src="videoActionIcon" alt="" />
        </div>
      </div>
      <!-- 分享点赞 -->
      <div class="share-box">
        <div class="item">
          <img :src="shareIcon" alt="" />
          <span>{{ contentData.share }}</span>
        </div>
        <div class="item">
          <img :src="commentIcon" alt="" />
          <span>{{ contentData.leave }}</span>
        </div>
        <div class="item">
          <img :src="noGoodUp" alt="" v-if="!contentData.goodUp" />
          <img :src="goodUp" alt="" v-else />
          <span>{{ contentData.like }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  contentData: {
    type: Object,
    default: () => ({}),
  },
});
// 头像
const userAvatar = require("@/assets/images/imitatepage/chuang-sheng-bang/user-avatar.png");
// 认证标识
const authAvatar = require("@/assets/images/imitatepage/chuang-sheng-bang/auth-avatar.png");
// 新闻图片
const newsImg = require("@/assets/images/imitatepage/chuang-sheng-bang/video-cover.png");
const videoActionIcon = require("@/assets/images/imitatepage/chuang-sheng-bang/video-action.png");

//分享、点赞、评论
const shareIcon = require("@/assets/images/imitatepage/chuang-sheng-bang/share-icon-gray.png");
const noGoodUp = require("@/assets/images/imitatepage/chuang-sheng-bang/no-good-up-gray.png");
const goodUp = require("@/assets/images/imitatepage/chuang-sheng-bang/good-up.png");
const commentIcon = require("@/assets/images/imitatepage/chuang-sheng-bang/comment-icon.png");

// 标签
const hotWordTagsList = [
  { lable: "软件开发", minWidth: 50 },
  { lable: "产品经理", minWidth: 50 },
];
</script>

<style lang="scss" scoped>
.consult-box {
  padding: 15px 0 0;
  border-top: 1px solid #f2f4f9;
  .user-info {
    display: grid;
    grid-template-columns: auto 1fr;

    // 左侧盒子
    &_left {
      position: relative;
      width: 50px;
      height: 50px;
      border-radius: 6px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }

      .auth-avatar {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 14px;
        height: 14px;
      }
    }

    // 右侧盒子
    &_right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-left: 10px;
      box-sizing: border-box;
      .info {
        .user-tag {
          display: flex;
          align-items: center;
          color: rgba(112, 126, 137, 1);
          width: 100%;
          margin: 2px 0;
          box-sizing: border-box;

          .tag-item {
            margin-right: 10px;

            &:last-child {
              margin-right: 0px;
            }

            span {
              display: inline-block;
              height: 18px;
              background: #f2f4f9;
              color: rgba(112, 126, 137, 1);
              border-radius: 4px;
              text-align: center;
              line-height: 18px;
              padding: 0px 5px;
              box-sizing: border-box;
              font-size: 10px;
            }
          }
        }

        .name {
          color: rgba(51, 51, 51, 1);
          font-size: 15px;
          font-weight: 700;
        }
      }

      //   关注按钮
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 24px;
        line-height: 24px;
        background: rgba($color: #708aff, $alpha: 0.2);
        border-radius: 12px;
        color: rgba(55, 91, 255, 1);
        font-size: 14px;
        font-weight: 700;

        .el-icon {
          margin-right: 2px;
        }
      }
    }
  }

  .content-box {
    .title-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      .title {
        font-size: 17px;
        color: rgba(51, 51, 51, 1);
        max-width: 276px;
        overflow: hidden;
        font-weight: 700;
      }

      span {
        font-size: 11px;
        color: rgba(153, 153, 153, 1);
      }
    }
    .content {
      font-size: 12px;
      color: rgba(102, 102, 102, 1);
      margin: 7px 0;
    }
    .video-box {
      position: relative;
      width: 100%;
      height: 188px;
      border-radius: 4px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }

      .video-action-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100px;
        height: 100px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    // 点赞
    .share-box {
      display: flex;
      padding: 13px 0;
      justify-content: space-around;

      .item {
        display: flex;
        align-items: center;
        color: rgba(102, 102, 102, 1);
        font-size: 11px;
        img {
          width: 15px;
          height: 16px;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
